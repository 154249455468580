import React from "react";

export const TypescriptIcon = (
  <img
    width={50}
    src={require("../images/skills/typescript.svg").default}
    alt="Typescript"
  />
);
export const JavascriptIcon = (
  <img
    width={50}
    src={require("../images/skills/javascript.svg").default}
    alt="Javascript"
  />
);
export const Html5Icon = (
  <img
    width={50}
    src={require("../images/skills/html5.svg").default}
    alt="Html5"
  />
);
export const CssIcon = (
  <img width={50} src={require("../images/skills/css.svg").default} alt="Css" />
);
export const ReactOriginalIcon = (
  <img
    width={50}
    src={require("../images/skills/react-original.svg").default}
    alt="ReactOriginal"
  />
);
export const ReactNativeIcon = (
  <img
    width={50}
    src={require("../images/skills/react-native.svg").default}
    alt="ReactNative"
  />
);
export const AngularIcon = (
  <img
    width={50}
    src={require("../images/skills/angular.svg").default}
    alt="Angular"
  />
);
export const JavaIcon = (
  <img
    width={50}
    src={require("../images/skills/java-original.svg").default}
    alt="Java"
  />
);
export const AndroidIcon = (
  <img
    width={50}
    src={require("../images/skills/android.svg").default}
    alt="Android"
  />
);
export const GitIcon = (
  <img
    width={50}
    src={require("../images/skills/git-original.svg").default}
    alt="Git"
  />
);
export const FigmaIcon = (
  <img
    width={50}
    src={require("../images/skills/figma.svg").default}
    alt="Figma"
  />
);
export const GradleIcon = (
  <img
    width={50}
    src={require("../images/skills/gradle.svg").default}
    alt="Gradle"
  />
);
export const MavenIcon = (
  <img
    width={50}
    src={require("../images/skills/maven.svg").default}
    alt="Maven"
  />
);
export const GatsbyIcon = (
  <img
    width={50}
    src={require("../images/skills/gatsby.svg").default}
    alt="Gatsby"
  />
);
export const StyledComponentsIcon = (
  <img
    width={50}
    src={require("../images/skills/styled-components.svg").default}
    alt="Styled Components"
  />
);
export const FramerMotionIcon = (
  <img
    width={50}
    src={require("../images/skills/framer-motion.svg").default}
    alt="Framer Motion"
  />
);
export const GraphQLIcon = (
  <img
    width={50}
    src={require("../images/skills/graph-ql.svg").default}
    alt="Graph QL"
  />
);
export const ReactTestingLibraryIcon = (
  <img
    width={50}
    src={require("../images/skills/react-testing-library.svg").default}
    alt="Graph QL"
  />
);
export const JestIcon = (
  <img
    width={50}
    src={require("../images/skills/jest.svg").default}
    alt="Graph QL"
  />
);
export const NetlifyIcon = (
  <img
    width={50}
    src={require("../images/skills/netlify.svg").default}
    alt="Graph QL"
  />
);
